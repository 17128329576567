<template>
  <slot
    :items="$dictionary.state.value.items"
    :loading="$dictionary.state.value.loading"
    :actions="$dictionary.actions"
    :selected="selected"
  />
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

interface Emits {
  (event: 'loaded'): void
}
interface Props {
  collection?: string | undefined
  path?: string | undefined
  filter?: IField | undefined
  value?: number | number[] | undefined
  disableFetching?: boolean
}
const emit = defineEmits<Emits>()
const props = withDefaults(
  defineProps<Props>(),
  {
    value: undefined,
    collection: undefined,
    path: undefined,
    filter: undefined,
    disableFetching: false
  }
)
const { collection, filter, disableFetching, path, value } = toRefs(props)

const $dictionary = useDictionary(collection.value)

const selected = computed(() => {
  if (value.value === undefined) {
    return undefined
  }

  if (Array.isArray(value.value)) {
    return $dictionary.state.value.items.filter((item: IField) => value.value.includes(item.id))
  }

  const item = $dictionary.state.value.items.find((item: IField) => item.id === value.value)

  if (!item) {
    return undefined
  }

  return item
})

const init = async () => {
  if (disableFetching.value) {
    return
  }
  if (filter.value) {
    $dictionary.mutations.setFilterValue(filter.value)
  }

  const [, data] = await $dictionary.actions.fetchItems(path.value)

  if (data) {
    emit('loaded')
  }
}

onMounted(async () => {
  await init()
})
onUnmounted(() => {
  $dictionary.actions.reset()
})
</script>
